import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { graphql } from 'gatsby'

let title = 'Uncertainties: Retrofitted Piers';
let metatitle = 'Uncertainties: Retrofitted Piers';
let description = '';
let metadescription = description;





const Retrofitted = ({ data }) => {
  
  let table = data.retro1.edges[0].node.Table;
  let theadvalues = table.TheadValues.split(",");
  let tbodyrows = table.TableRow;
  let tbodyval = [];
  tbodyrows.forEach(row => {
    let bodyval = row.TbodyRowValues.split(",");
    tbodyval.push(bodyval);
  });

  let table3 = data.retro3.edges[0].node.Table;
  let theadvalues3 = table3.TheadValues.split(";");
  let tbodyrows3 = table3.TableRow;
  let tbodyval3 = [];
  tbodyrows3.forEach(row => {
    let bodyval3 = row.TbodyRowValues.split(";");
    tbodyval3.push(bodyval3);
  });
  let style = {
    overflowX: 'auto' 
  };
  
    
    return(
      
        <Layout bodyclass="as-built" title={title}>
          <SEO
            title={metatitle}
            description={metadescription}
          />
          <h2>Uncertainties in Capacity (β<sub>c</sub>)</h2>
          <div style={style}>
          <table className="plaintable">
            <thead>
              <tr>
                {theadvalues.map((th,index)=>{
                  var column = th;
                  if(index == 5){
                    column = '<span style="color: #000;"><strong>'+th+'</strong></span>';
                  }
                  return(
                    
                    <th dangerouslySetInnerHTML={{__html: column}} />
                  )
                })}
              </tr>
            </thead>
            <tbody>
            {tbodyval.map(tr=>{
                return(
                  <tr>
                    {tr.map((td,index)=>{
                      var column2 = td;
                      if(index == 5){
                        column2 = '<span style="color: #000;"><strong>'+td+'</strong></span>';
                      }
                      return(
                        <td dangerouslySetInnerHTML={{__html: column2}} />
                      )
                    })}
                  </tr>
                )
              })}
              
            </tbody>
          </table>
          </div>
          <p style={{marginBottom:10}}>Reference</p>
          <p>Stefanidou, S. P., &amp; Kappos, A. J. (2017). Methodology for the development of bridge-specific fragility curves. Earthquake Engineering &amp; Structural Dynamics, 46, 73–93. <a href="https://doi.org/10.1002/eqe.2774" target="_blank">https://doi.org/10.1002/eqe.2774</a></p>
          
          <h2 style={{paddingTop:40,marginTop:40,borderTop:'1px solid #e6e6e6'}}>Uncertainties in Limit State definition (β<sub>LS</sub>)</h2>
          <p style={{paddingBottom:20,paddingTop:0}}>Uncertainty in Limit State definition for all different pier types, considering drift (%) as EDP</p>
          <div style={style}>
          <table className="plaintable">
            <thead>
              <tr>
                {theadvalues3.map((th,index)=>{
                  var column = th;
                  if(index == 5){
                    column = '<span style="color: #000;"><strong>'+th+'</strong></span>';
                  }
                  return(
                    <th dangerouslySetInnerHTML={{__html: column}} />
                  )
                })}
              </tr>
            </thead>
            <tbody>
            {tbodyval3.map(tr=>{
                return(
                  <tr>
                    {tr.map((td,index)=>{
                      var column2 = td;
                      // if(index == 5){
                      //   column2 = '<span style="color: #000;"><strong>'+td+'</strong></span>';
                      // }
                      return(
                        <td dangerouslySetInnerHTML={{__html: column2}} />
                      )
                    })}
                  </tr>
                )
              })}
              
            </tbody>
          </table>
          </div>
          <em style={{fontSize:12,paddingBottom:30,display:'block'}}>* Values based on statistical analysis of the limit state definitions in literature, available in the database.</em>
        </Layout>
      )
    
}

export default Retrofitted

export const query2 = graphql`
  query Uncertaintiesretro {
    retro1: allStrapiUncertaintiesTables(filter: {id: {eq: "Uncertainties-tables_2"}}) {
      edges {
        node {
          id
          Table {
            TheadValues
            id
            TableRow {
              id
              TbodyRowValues
            }
          }
        }
      }
    }
    retro3: allStrapiUncertaintiesTables(filter: {id: {eq: "Uncertainties-tables_5"}}) {
      edges {
        node {
          id
          Table {
            TheadValues
            id
            TableRow {
              id
              TbodyRowValues
            }
          }
        }
      }
    }
}
`
